










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.main_institutions_wrapper {
  .el-dialog__body {
    @media (min-width: 320px) and (max-width: 767px) {
      padding: 30px 5px;
    }
  }
  .push_dialog_task_user {
    .el-dialog {
      @media (min-width: 320px) and (max-width: 767px) {
        width: 90% !important;
      }
    }
  }
}
